import React, { useState, useEffect, useRef } from 'react';
import { Terminal } from 'lucide-react';

const TerminalWebsite = () => {
  const [lines, setLines] = useState([]);
  const [currentLine, setCurrentLine] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [awaitingUserInput, setAwaitingUserInput] = useState(false);
  const [animatingOutput, setAnimatingOutput] = useState(false);
  const [currentDirectory, setCurrentDirectory] = useState('sswebsite');
  const [previousDirectory, setPreviousDirectory] = useState('');
  const inputRef = useRef(null);

  const getPrompt = (dir) => `shadmansian@sswebsite ${dir} % `;
  const initialCommands = [
    { command: 'echo "Hi.."', output: 'Hi..' },
    { command: 'echo "I\'m a 3x startup founder.."', output: "I'm a 3x startup founder.." },
    { command: 'echo "type ls to proceed"', output: "type ls to proceed" },
  ];

  const directories = {
    'sswebsite': ['About_Me', 'Startups', 'Projects', 'Tech_Stack', 'Get_in_touch'],
    'Startups': ['Annihilation_Mobile', 'Exeaio', 'Fitflo'],
  };

  const directoryContent = {
    'About_Me': "My philosophy of life is 'ABB' always be building. I'm Shadman Sian, 22 y.o, based in Melbourne, Australia. So far I've founded 3 startups. When I was 17, I started my first startup 'Annihilation Mobile' (a fortnite-like battle royale for low-end devices) which had around 10k DAU and 300k downloads. We raised around 240k in crowdfunding. But it failed due to an unsustainable business model (kidding...I didn't know anything about running a business). For my 2nd startup Exeaio, I built 'SkidVR' a VR bike simulator for metaquest but failed to get enough traction. I was the CTO and co-founder for my third startup 'Fitflo' (an operating system for gym). We raised 225k pre-seed round from Antler and had 40 gyms as customers and 1k MAU. I left fitflo to build something more AI focused. When I'm not building, I'm stream sniping or hitting the gym. My goal is to build a unicorn by 30, so yeah 8 more years to go.",
    'Tech_Stack': "Python\nReact\nFlutter\nFirebase\nTypescript\nNode.js\nDjango\nPinecone\nSupabase\nNext.js\nGCP\nAWS\nRest_API\nSolidity\nUnity",
    'Get_in_touch': "Email: shadman23sian@gmail.com\nLinkedIn: https://www.linkedin.com/in/s-sian-a29665190/",
    'Projects': "https://paysum.org/",
    'Annihilation_Mobile': "https://play.google.com/store/apps/details?id=com.CrisisEntertainmentLtd.Annihilation&hl=en_AU",
    'Exeaio': "https://sidequestvr.com/app/10779/skid",
    'Fitflo': "https://www.fitfloapp.com/"
  };

  const animateText = (text, callback) => {
    let i = 0;
    setAnimatingOutput(true);
    const intervalId = setInterval(() => {
      setCurrentLine(prev => text.slice(0, i + 1));
      i++;
      if (i === text.length) {
        clearInterval(intervalId);
        setAnimatingOutput(false);
        if (callback) callback();
      }
    }, 50);
  };

  const redirectToUrl = (url) => {
    window.open(url, '_blank');
  };

  const displayDirectoryContent = (dir) => {
    const content = directoryContent[dir];
    if (content) {
      animateText(content, () => {
        setLines(prev => {
          const newLines = [...prev];
          newLines[newLines.length - 1].output = content;
          return newLines;
        });
        setCurrentLine('');
        setAwaitingUserInput(true);
      });
      if (dir === 'Projects' || dir === 'Annihilation_Mobile' || dir === 'Exeaio' || dir === 'Fitflo') {
        redirectToUrl(content);
      }
    }
  };

  useEffect(() => {
    if (currentIndex < initialCommands.length) {
      const { command, output } = initialCommands[currentIndex];
      const fullCommand = `${getPrompt(currentDirectory)}${command}`;

      if (!animatingOutput) {
        animateText(fullCommand, () => {
          setLines(prev => [...prev, { command: fullCommand, output: '' }]);
          setCurrentLine('');
          animateText(output, () => {
            setLines(prev => {
              const newLines = [...prev];
              newLines[newLines.length - 1].output = output;
              return newLines;
            });
            setCurrentLine('');
            setCurrentIndex(currentIndex + 1);
          });
        });
      }
    } else if (currentIndex === initialCommands.length && !animatingOutput) {
      setAwaitingUserInput(true);
    }
  }, [currentIndex, animatingOutput, currentDirectory]);

  const handleUserInput = (e) => {
    if (e.key === 'Enter' && !animatingOutput) {
      e.preventDefault();
      const command = userInput.trim();
      setUserInput('');
      setAwaitingUserInput(false);

      setLines(prev => [...prev, { command: `${getPrompt(currentDirectory)}${command}`, output: '' }]);

      const [cmd, ...args] = command.split(' ');

      switch (cmd.toLowerCase()) {
        case 'ls':
          const lsOutput = directories[currentDirectory]?.join('    ') || '';
          animateText(lsOutput, () => {
            setLines(prev => {
              const newLines = [...prev];
              newLines[newLines.length - 1].output = lsOutput;
              return newLines;
            });
            setCurrentLine('');
            setAwaitingUserInput(true);
          });
          break;

        case 'cd':
          if (args[0] === '-') {
            if (previousDirectory) {
              const temp = currentDirectory;
              setCurrentDirectory(previousDirectory);
              setPreviousDirectory(temp);
              displayDirectoryContent(previousDirectory);
            } else {
              animateText('cd: no previous directory', () => {
                setLines(prev => {
                  const newLines = [...prev];
                  newLines[newLines.length - 1].output = 'cd: no previous directory';
                  return newLines;
                });
                setCurrentLine('');
                setAwaitingUserInput(true);
              });
            }
          } else if (args[0] === '..') {
            if (currentDirectory !== 'sswebsite') {
              setPreviousDirectory(currentDirectory);
              setCurrentDirectory('sswebsite');
              setCurrentLine('');
              setAwaitingUserInput(true);
            } else {
              animateText('cd: already at root directory', () => {
                setLines(prev => {
                  const newLines = [...prev];
                  newLines[newLines.length - 1].output = 'cd: already at root directory';
                  return newLines;
                });
                setCurrentLine('');
                setAwaitingUserInput(true);
              });
            }
          } else if (directories[currentDirectory]?.includes(args[0])) {
            setPreviousDirectory(currentDirectory);
            setCurrentDirectory(args[0]);
            displayDirectoryContent(args[0]);
          } else {
            animateText(`cd: no such file or directory: ${args[0]}`, () => {
              setLines(prev => {
                const newLines = [...prev];
                newLines[newLines.length - 1].output = `cd: no such file or directory: ${args[0]}`;
                return newLines;
              });
              setCurrentLine('');
              setAwaitingUserInput(true);
            });
          }
          break;

        case 'cat':
          if (currentDirectory === 'sswebsite') {
            if (directoryContent[args[0]]) {
              animateText(directoryContent[args[0]], () => {
                setLines(prev => {
                  const newLines = [...prev];
                  newLines[newLines.length - 1].output = directoryContent[args[0]];
                  return newLines;
                });
                setCurrentLine('');
                setAwaitingUserInput(true);
              });
            } else {
              animateText(`cat: ${args[0]}: No such file or directory`, () => {
                setLines(prev => {
                  const newLines = [...prev];
                  newLines[newLines.length - 1].output = `cat: ${args[0]}: No such file or directory`;
                  return newLines;
                });
                setCurrentLine('');
                setAwaitingUserInput(true);
              });
            }
          } else {
            animateText(`cat: ${args[0]}: No such file or directory`, () => {
              setLines(prev => {
                const newLines = [...prev];
                newLines[newLines.length - 1].output = `cat: ${args[0]}: No such file or directory`;
                return newLines;
              });
              setCurrentLine('');
              setAwaitingUserInput(true);
            });
          }
          break;

        default:
          const output = `zsh: command not found: ${cmd}`;
          animateText(output, () => {
            setLines(prev => {
              const newLines = [...prev];
              newLines[newLines.length - 1].output = output;
              return newLines;
            });
            setCurrentLine('');
            setAwaitingUserInput(true);
          });
      }
    }
  };

  useEffect(() => {
    if (awaitingUserInput) {
      inputRef.current.focus();
    }
  }, [awaitingUserInput]);

  return (
    <div className="flex items-center justify-center h-screen bg-black text-green-400 font-mono">
      <div className="w-full max-w-3xl h-96 bg-black rounded-lg p-4 shadow-lg border-2 border-green-400 overflow-y-auto">
        <div className="flex items-center mb-4">
          <Terminal size={24} />
          <span className="ml-2 text-xl">Terminal</span>
        </div>
        <div className="text-sm">
          {lines.map((line, index) => (
            <div key={index}>
              <div>{line.command}</div>
              <div style={{ whiteSpace: 'pre-wrap' }}>{line.output}</div>
            </div>
          ))}
          <div>{currentLine}<span className="animate-blink">_</span></div>
          {awaitingUserInput && (
            <div className="flex">
              <span>{getPrompt(currentDirectory)}</span>
              <input
                ref={inputRef}
                type="text"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleUserInput}
                className="flex-grow bg-transparent outline-none ml-1"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TerminalWebsite;